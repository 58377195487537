import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import store from '@/store'

export default {
  mixins: [MixinUserSubscription],

  computed: {
    userHasAccessToIdeaScoreTest () {
      if (this.subscriptionIsFreeTrial || this.subscriptionIsDreamer) return false

      return store.getters['idea/isRoleOwner']
    }
  }
}
