var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idea-score-test-top-nav d-flex justify-content-between",class:{'disabled': _vm.disabled},attrs:{"id":"idea-score-top-nav"}},[_vm._l((_vm.data),function(category,index){return [_c('div',{key:category.id,staticClass:"navigation-item d-flex position-relative py-2 py-xl-3 px-4 align-items-center",class:{
        'first-item': index === 0,
        'in-progress': _vm.isCategoryInProgress(category.id),
        'active': category.id === _vm.activeCategoryId,
        'finished': _vm.isCategoryFinished(category.id),
        'disabled': _vm.isCategoryDisabled(category.id) && category.id !== _vm.activeCategoryId
      },attrs:{"id":`category-${category.id}`},on:{"click":function($event){return _vm.onClick(category.id)}}},[_c('span',{staticClass:"name",class:{'ml-4': index !== 0}},[_vm._v(_vm._s(_vm.$t(category.name)))])])]}),_c('div',{staticClass:"navigation-item d-flex position-relative py-2 py-xl-3 px-4 align-items-center",class:{
      'finished': _vm.isIdeaScoreEnabled,
      'disabled': !_vm.isIdeaScoreEnabled,
      'active': _vm.activeCategoryId === null
    },attrs:{"id":"category-idea-score"},on:{"click":_vm.onIdeaScoreClick}},[_c('span',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(_vm.$t('pages.businessGuide.validation.ideaScore.finalScore'))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }