<template>
  <div
    id="idea-score-top-nav"
    class="idea-score-test-top-nav d-flex justify-content-between"
    :class="{'disabled': disabled}"
  >
    <template v-for="(category, index) in data">
      <div
        :id="`category-${category.id}`"
        :key="category.id"
        class="navigation-item d-flex position-relative py-2 py-xl-3 px-4 align-items-center"
        :class="{
          'first-item': index === 0,
          'in-progress': isCategoryInProgress(category.id),
          'active': category.id === activeCategoryId,
          'finished': isCategoryFinished(category.id),
          'disabled': isCategoryDisabled(category.id) && category.id !== activeCategoryId
        }"
        @click="onClick(category.id)"
      >
        <span
          class="name"
          :class="{'ml-4': index !== 0}"
        >{{ $t(category.name) }}</span>
      </div>
    </template>
    <div
      id="category-idea-score"
      class="navigation-item d-flex position-relative py-2 py-xl-3 px-4 align-items-center"
      :class="{
        'finished': isIdeaScoreEnabled,
        'disabled': !isIdeaScoreEnabled,
        'active': activeCategoryId === null
      }"
      @click="onIdeaScoreClick"
    >
      <span class="name ml-4">
        {{ $t('pages.businessGuide.validation.ideaScore.finalScore') }}
      </span>
    </div>
  </div>
</template>

<script>
import _find from 'lodash/find'
import _head from 'lodash/head'
import _isEmpty from 'lodash/isEmpty'

import businessGuideNavigationsSteps from '@/data/businessGuideNavigationsSteps'

export default {
  name: 'IdeaScoreTestTopNavigation',

  props: {
    data: {
      type: Array,
      required: true
    },

    activeCategory: {
      validator: value => typeof value === 'object' || value === null,
      required: true
    },

    completedCategories: {
      validator: value => typeof value === 'object' || value === null,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    activeCategoryId () {
      return this.activeCategory ? this.activeCategory.id : null
    },

    isIdeaScoreEnabled () {
      return !_isEmpty(this.completedCategories) ? Object.values(this.completedCategories).every(item => item) : false
    }
  },

  watch: {
    activeCategoryId: {
      handler (value) {
        this.scrollToCategory(value)
      },
      immediate: true
    }
  },

  methods: {
    onClick (categoryId) {
      if (this.isCategoryDisabled(categoryId)) return
      if (this.activeCategoryId === categoryId) return
      this.scrollToCategory(categoryId)

      this.$emit('click', this.findFirstSectionOfCategory(categoryId))
    },

    onIdeaScoreClick () {
      if (!this.isIdeaScoreEnabled) return
      this.$emit('click', null)
    },

    isCategoryDisabled (categoryId) {
      return !this.isCategoryFinished(categoryId) && !this.isCategoryFinished(categoryId - 1)
    },

    isCategoryFinished (categoryId) {
      return !!this.completedCategories[categoryId]
    },

    isCategoryInProgress (categoryId) {
      if (!_isEmpty(this.completedCategories)) {
        const obj = this.completedCategories
        const keys = Object.keys(obj)
        const filtered = keys.filter(function (key) {
          return !obj[key]
        })

        return Number(_head(filtered)) === categoryId
      }

      return true
    },

    findFirstSectionOfCategory (categoryId) {
      const category = _find(Object.values(businessGuideNavigationsSteps), ['id', categoryId])
      if (category) {
        return _head(category.children).id
      }

      return null
    },

    scrollToCategory (categoryId) {
      const element = document.querySelector(`#category-${categoryId || 'idea-score'}`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.idea-score-test-top-nav {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 14%), 0 0 2px 0 rgb(0 0 0 / 12%);
  background-color: $color-white;
  min-height: 60px;
  overflow-x: auto;
  border-radius: 8px;

  &.disabled {
    opacity: 0.6;
  }

  .navigation-item.active,
  .navigation-item.in-progress {
    &:after {
      margin-left: 0;
    }
    &:before {
      margin-left: 0;
    }
  }

  .navigation-item.active.finished {
    &:after {
      margin-left: -2px;
    }
  }

  .navigation-item.disabled {
    &:before {
      border-left-color: $color-text-lightest-gray;
      margin-left: 2px;
    }
    &:after {
      border-left-color: $color-white;
      margin-left: 0;
    }
  }

  .navigation-item.active.first-item.in-progress {
    &:after {
      border-left: 0;
    }
    &:before {
      border-left: 0;
    }
  }

  .navigation-item.finished.first-item,
  .navigation-item.active.first-item {
    &:after {
      border-left-color: $color-primary;
      margin-left: 0;
    }
    &:before {
      border-left-color: $color-primary;
      margin-left: 0;
    }
  }

  .navigation-item {
    cursor: pointer;
    width: 100%;

    &:before{
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 30px solid $color-white;
    }

    &:after{
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 30px solid $color-primary;
      margin-left: -2px;
    }

    .name {
      white-space: nowrap;
      z-index: 1;
    }

    &.active {
      color: $color-royal-blue;
      font-family: $outfit-semi-bold;

      &.finished {
        font-family: $outfit-semi-bold;
        .name {
          color: $color-white;
          opacity: 1;
        }
      }
    }

    &.disabled {
      color: $color-text-gray;
      cursor: not-allowed;
    }

    &.finished {
      background-color: $color-primary;
      .name {
        color: $color-white;
        opacity: 0.5;
      }
    }
  }
}
</style>
