<template>
  <ib-dialog
    :visible="visible"
    fullscreen
    empty-header
    @close="$emit('close')"
  >
    <div class="background-wrapper">
      <ib-dialog-container max-width="900px">
        <div class="init-dialog-head text-center">
          <h1 class="mb-0">
            {{ $t('dialogs.validation.congratsDialog.congratulations') }}
          </h1>
          <p class="m-0">
            {{ $t('dialogs.validation.congratsDialog.youHaveSuccessfullyTested') }}
          </p>
        </div>

        <ib-dialog-container max-width="460px">
          <ib-card
            class="congratulation-dialog-card mb-3 mt-5"
            :animation="false"
          >
            <div class="text-center mb-5">
              <img
                class="d-block m-auto"
                src="@/assets/img/illustrations/idea_score.svg"
                alt=""
              >
              <p class="m-0 h4">
                {{ $t('dialogs.validation.congratsDialog.ideaScore') }}
              </p>
            </div>

            <div>
              <p class="title text-center">
                {{ $t('dialogs.validation.congratsDialog.soWhatsNext') }}
              </p>
              <idea-score-checkmark-text
                class="mb-3"
                :text="$t('dialogs.validation.congratsDialog.ifYouAreHappy')"
              />

              <idea-score-checkmark-text
                class="mb-3"
                :text="$t('dialogs.validation.congratsDialog.ifYouAreUnsatisfied')"
              />

              <idea-score-checkmark-text
                :text="$t('dialogs.validation.congratsDialog.ifYouArentSure')"
              />
            </div>
          </ib-card>

          <ib-button
            class="p-3 w-100 text-uppercase"
            @click="$emit('continue')"
          >
            {{ $t('continue') }}
          </ib-button>
        </ib-dialog-container>
      </ib-dialog-container>
    </div>
  </ib-dialog>
</template>

<script>
import IdeaScoreCheckmarkText from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreCheckmarkText'

export default {
  name: 'DialogValidationCongratulations',

  components: { IdeaScoreCheckmarkText },

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.background-wrapper {
  display: block;
  width: auto;
  height: auto;
  margin: 0 auto;
  background-image: url("~@/assets/img/illustrations/confetti.png");
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1100px;

  @include media-breakpoint-up($xl) {
    width: 900px;
  }

  .title {
    font-family: $outfit-semi-bold;
    font-size: 16px;
  }

  .init-dialog-head {
    margin-top: 2vh;

    @include media-breakpoint-up($md) {
      margin-top: 4vh;
    }

    p {
      font-size: 16px;
    }
  }

  .congratulation-dialog-card {
    padding: 45px 10px;
    min-height: 450px;

    @include media-breakpoint-up($sm) {
      padding: 28px 31px 45px 31px;
    }
  }
}
</style>
