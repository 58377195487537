<template>
  <div class="progress-wrapper w-100">
    <div class="d-flex align-items-center">
      <div class="progress-title">
        {{ title ? title : $t('progress') }}
      </div>
      <div class="flex-grow-1">
        <!-- Progress -->
        <el-progress :percentage="percentage" :stroke-width="8" :color="progressBarCustomColorMethod" />
        <!-- /Progress -->
      </div>
    </div>
  </div>
</template>

<script>
import MixinProgressBar from '@/mixins/progressBar'

export default {
  name: 'ProgressCard',

  mixins: [
    MixinProgressBar
  ],

  props: {
    percentage: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .progress-wrapper {
    box-sizing: border-box;
    background-color: $color-white;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);

    @include media-breakpoint-up($md) {
      max-width: 350px;
    }
  }

  .progress-title {
    margin-right: 20px;
  }
</style>
