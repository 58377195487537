<template>
  <button
    class="ib-navigation-btn-next d-flex align-items-center"
    :class="{'disabled': disabled}"
    @click="onClick"
  >
    <i class="el-icon-back icon" />
    <span class="d-flex flex-column justify-content-center align-items-start text-left ml-3">
      <span
        class="text"
        :class="{'no-title': !title}"
      >
        {{ btnText }}
      </span>
      <span v-if="title" class="title mt-1">{{ title }}</span>
    </span>
  </button>
</template>

<script>

export default {
  name: 'IbNavigationButtonBack',

  props: {
    text: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    btnText () {
      if (this.text) return this.text

      return this.$t('back')
    }
  },

  methods: {
    onClick () {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.ib-navigation-btn-next {
  min-width: 200px;
  min-height: 56px;
  padding: 10px 15px;
  border: unset;
  border-radius: 8px;
  cursor: pointer;
  background-color: $color-white;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 14%), 0 0 2px 0 rgb(0 0 0 / 12%);

  .title, .icon {
    opacity: 0.5;
    color: rgba(41, 47, 77, 0.5);;
  }

  .icon {
    font-size: 30px;
  }

  .text {
    font-family: $outfit-semi-bold;
    font-size: 14px;

    &.no-title {
      font-size: 16px;
    }
  }

  .title {
    font-size: 12px;

    @include media-breakpoint-up($md) {
      font-size: 14px;
    }
  }

  .text, .title {
    line-height: 1.21;
    color: $color-navy;
  }

  &.disabled {
    background-color: $color-white;
    opacity: 0.5;
    cursor: not-allowed;
    border-color: #EBEEF5;
  }
}
</style>
