<script>
import _cloneDeep from 'lodash/cloneDeep'
import _find from 'lodash/find'
import _head from 'lodash/head'
import _isEqual from 'lodash/isEqual'

import businessGuideNavigationsSteps from '@/data/businessGuideNavigationsSteps'

export default {
  name: 'IdeaScoreServiceProvider',

  provide () {
    return {
      getIsFirstSection: () => this.isFirstSection
    }
  },

  data () {
    return {
      activeSection: 1,
      loading: false,
      saving: false,
      questions: null,
      userTestAnswers: {},
      initialTestAnswers: {},
      completedCategories: {},
      score: null,
      hasChange: false,
      isTestFinished: false
    }
  },

  computed: {
    businessGuideCategories () {
      return Object.values(businessGuideNavigationsSteps).filter(item => item.id !== null)
    },

    businessGuideSteps () {
      return Object.values(businessGuideNavigationsSteps).reduce((a, item) => {
        return a.concat(item.children)
      }, []).filter(item => item.id !== null)
    },

    activeCategoryTest () {
      return this.activeSectionQuestions ? _head(this.activeSectionQuestions) : null
    },

    activeSectionTest () {
      return this.activeSectionQuestions ? _head(this.activeSectionQuestions).section : null
    },

    nextSectionQuestionsTest () {
      return this.questions ? this.questions.filter(question => question.section === this.activeSection + 1) : null
    },

    currentCategory () {
      return this.activeCategoryTest ? _find(Object.values(businessGuideNavigationsSteps), ['id', this.activeCategoryTest.category]) : null
    },

    currentSection () {
      return this.currentCategory ? _find(this.currentCategory.children, ['id', this.activeSectionTest]) : null
    },

    nextSection () {
      return this.currentCategory ? _find(this.businessGuideSteps, ['id', this.activeSectionTest + 1]) : null
    },

    previousSection () {
      return this.currentCategory ? _find(this.businessGuideSteps, ['id', this.activeSectionTest - 1]) : null
    },

    activeSectionQuestions () {
      return this.questions ? this.questions.filter(question => question.section === this.activeSection) : null
    },

    progressPercentage () {
      return this.score ? this.score.progress : 0
    },

    isNextBtnDisable () {
      return !this.isSectionFinished(this.activeSectionQuestions)
    },

    isNextSectionFinished () {
      return this.isSectionFinished(this.nextSectionQuestionsTest)
    },

    isFirstSection () {
      return typeof this.previousSection === 'undefined' && this.activeSection === _head(this.businessGuideSteps).id
    }
  },

  watch: {
    userTestAnswers: {
      handler (value) {
        this.hasChange = !_isEqual(value, this.initialTestAnswers)
      },
      deep: true
    }
  },

  created () {
    this.getScoreTest()
  },

  methods: {
    getScoreTest () {
      this.loading = true

      return this.$http.get(`story-mode/validation/idea-score/test/${this.$store.state.idea.id}`)
        .then(response => response.data.payload)
        .then((response) => {
          this.$store.commit('idea/setIdeaScore', response.score)
          this.score = response.score
          this.questions = response.questions
          this.userTestAnswers = response.testAnswers ?? {}
          this.initialTestAnswers = _cloneDeep(response.testAnswers) ?? {}
          this.completedCategories = response.completedCategories ?? {}
          this.isTestFinished = (this.score && this.score.progress) ? this.score.progress === 100 : false
          this.continueTest(response.lastCompletedSection)

          return response
        }).finally(() => {
          this.loading = false
        })
    },

    continueTest (lastCompletedSection) {
      if (this.score && this.score.progress) {
        if (this.score.progress < 100) {
          this.setActiveSection(lastCompletedSection)
        }
      }
    },

    saveScoreTest () {
      this.saving = true

      return this.$http.put(`story-mode/validation/idea-score/test/${this.$store.state.idea.id}`, { data: this.userTestAnswers })
        .then(response => response.data.payload)
        .then((response) => {
          this.$store.commit('idea/setIdeaScore', response.score)
          this.score = response.score
          this.userTestAnswers = response.testAnswers ?? {}
          this.initialTestAnswers = _cloneDeep(response.testAnswers) ?? {}
          this.completedCategories = response.completedCategories ?? {}

          return response
        }).finally(() => {
          this.saving = false
        })
    },

    setActiveSection (section) {
      if (this.hasChange) {
        this.saveScoreTest().then(() => {
          this.activeSection = section
        })
      } else {
        this.activeSection = section
      }
    },

    next () {
      if (this.hasChange) {
        this.saveScoreTest().then(() => {
          this.incrementSection()
        })
      } else {
        this.incrementSection()
      }
    },

    back () {
      if (this.hasChange) {
        this.saveScoreTest().then(() => {
          this.decrementSection()
        })
      } else {
        this.decrementSection()
      }
    },

    incrementSection () {
      this.scrollToContent()
      this.activeSection++
    },

    decrementSection () {
      if (this.isFirstSection) return this.$router.push({ name: 'validation-idea-score' })
      this.scrollToContent()
      this.activeSection--
    },

    onTestFinish () {
      if (!this.activeSection) return

      this.saveScoreTest()
        .then(() => {
          this.setActiveSection(null)
        })
    },

    onFinalStep () {
      if (!this.isTestFinished && this.score.calculation) {
        this.$emit('congrats')

        return
      }
      this.$emit('exit')
    },

    isSectionFinished (questions) {
      if (questions) {
        const answerIds = questions.map(question => question.id)

        const containsAll = answerIds.every(element => {
          return Object.keys(this.userTestAnswers).includes(String(element))
        })

        return !!containsAll
      }

      return false
    },

    scrollToContent () {
      const content = document.querySelector('.content')
      if (content) {
        content.scrollIntoView()
      }
    }
  },

  render () {
    return this.$scopedSlots.default({
      // computed
      activeSectionQuestions: this.activeSectionQuestions,
      isNextBtnDisable: this.isNextBtnDisable,
      currentCategory: this.currentCategory,
      currentSection: this.currentSection,
      nextSection: this.nextSection,
      previousSection: this.previousSection,
      businessGuideCategories: this.businessGuideCategories,
      progressPercentage: this.progressPercentage,

      // data
      loading: this.loading,
      saving: this.saving,
      userTestAnswers: this.userTestAnswers,
      activeSection: this.activeSection,
      questions: this.questions,
      completedCategories: this.completedCategories,
      isTestFinished: this.isTestFinished,

      // methods
      next: this.next,
      back: this.back,
      setActiveSection: this.setActiveSection,
      saveScoreTest: this.saveScoreTest,
      onTestFinish: this.onTestFinish,
      onFinalStep: this.onFinalStep
    })
  }
}
</script>
