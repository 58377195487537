<template>
  <div id="story-mode-idea-score-test">
    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div class="content">
        <header class="page-header">
          <!-- Dashboard Header -->
          <dashboard-header
            :title="$t('sidebar.validation')"
            hide-quick-tour-button
            hide-guide-button
          />
          <!-- /Dashboard Header -->

          <!-- Divider -->
          <hr>
          <!-- /Divider -->
        </header>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Idea Score Test Service Provider -->
        <idea-score-test-service-provider
          v-slot="{
            loading, saving, onTestFinish, userTestAnswers, activeSectionQuestions,
            isNextBtnDisable, currentSection, next, back,
            nextSection, previousSection, businessGuideCategories, currentCategory, completedCategories,
            setActiveSection, activeSection, progressPercentage, onFinalStep, isTestFinished
          }"
          @congrats="dialogValidationCongratulationsVisible = true"
          @exit="$router.push({name: 'validation-idea-score'})"
        >
          <div>
            <div v-if="loading">
              <loader />
            </div>

            <!-- Idea Score Test Top Navigation -->
            <idea-score-test-top-navigation
              v-if="!loading"
              :disabled="saving"
              :data="businessGuideCategories"
              :active-category="currentCategory"
              :completed-categories="completedCategories"
              class="mb-4"
              @click="setActiveSection($event)"
            />
            <!-- Idea Score Test Top Navigation -->

            <div
              v-if="!loading && currentSection"
              class="d-flex justify-content-between align-items-md-center flex-column-reverse flex-md-row"
            >
              <div class="d-flex align-items-center mt-4 mt-md-0">
                <h2 class="m-0">
                  {{ $t(currentSection.name) }}
                </h2>
                <ib-tooltip
                  popper-class="medium center"
                  :content="$t('pages.businessGuide.validation.ideaScore.goToThisSection')"
                >
                  <router-link
                    class="h4 m-0"
                    target="_blank"
                    :to="{ name: currentSection.route }"
                  >
                    <i class="ib-icon-external-link ml-2" />
                  </router-link>
                </ib-tooltip>
              </div>
              <progress-card
                v-if="!loading"
                :percentage="progressPercentage"
              />
            </div>

            <div v-if="!loading && activeSection" class="questions-wrapper mt-4 p-4">
              <div
                v-for="question in activeSectionQuestions"
                :key="question.id"
                class="mb-4"
              >
                <h4 class="mt-0 mb-3">
                  {{ $t(`pages.businessGuide.validation.ideaScore.ideaScoreTest.questions.${question.text.replace("'", "")}`) }}
                </h4>

                <el-radio-group v-model="userTestAnswers[question.id]">
                  <el-radio
                    v-for="answer in question.answers"
                    :key="answer.id"
                    :label="answer.id"
                    class="d-block mb-1"
                  >
                    {{ $t(`pages.businessGuide.validation.ideaScore.ideaScoreTest.answers.${answer.text}`) }}
                  </el-radio>
                </el-radio-group>
              </div>
            </div>

            <div
              v-if="!activeSection"
              class="mt-5 d-flex flex-column align-items-center"
            >
              <idea-score-progress-card class="score-progress-card" />
              <p class="disclaimer-text o-50 my-5">
                {{ $t('pages.businessGuide.validation.ideaScore.disclaimerMessage') }}
              </p>
            </div>

            <!-- Idea Score Bottom Navigation -->
            <idea-score-test-bottom-navigation
              v-if="!loading"
              class="my-4"
              :disabled="saving"
              :disable-next-btn="isNextBtnDisable"
              :next-section="nextSection"
              :previous-section="previousSection"
              @next="next"
              @back="back"
              @finish="onTestFinish"
              @final-step="!isTestFinished ? onFinalStep() : $router.push({name: 'validation-idea-score'})"
            />
            <!-- /Idea Score Bottom Navigation -->
          </div>
        </idea-score-test-service-provider>
        <!-- /Idea Score Test Service Provider -->

        <!-- Dialog Congratulations -->
        <dialog-validation-congratulations
          :visible="dialogValidationCongratulationsVisible"
          @continue="$router.push({name: 'validation-idea-score'})"
          @close="dialogValidationCongratulationsVisible = false"
        />
        <!-- /Dialog Congratulations -->
      </div>
    </div>
  </div>
</template>

<script>
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogValidationCongratulations from '@/views/Home/StoryMode/Validation/IdeaScore/Dialogs/DialogValidationCongratulations'
import IdeaScoreProgressCard from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreProgressCard'
import IdeaScoreTestBottomNavigation from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreTestBottomNavigation'
import IdeaScoreTestServiceProvider from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreTestServiceProvider'
import IdeaScoreTestTopNavigation from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreTestTopNavigation'
import Loader from '@/components/Loader'
import MixinIdeaScoreTest from '@/mixins/permissions/ideaScoreTest'
import ProgressCard from '@/components/_v2/ProgressCard'

export default {
  name: 'IdeaScoreTest',

  components: {
    DashboardHeader,
    DialogValidationCongratulations,
    IdeaScoreProgressCard,
    IdeaScoreTestBottomNavigation,
    IdeaScoreTestServiceProvider,
    IdeaScoreTestTopNavigation,
    Loader,
    ProgressCard
  },

  mixins: [MixinIdeaScoreTest],

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.userHasAccessToIdeaScoreTest) next({ name: 'home' })
    })
  },

  data () {
    return {
      dialogValidationCongratulationsVisible: false
    }
  }
}
</script>

<style scoped lang="scss">
#story-mode-idea-score-test {
  margin-bottom: 50px;

  .content {
    padding-top: 12px;

    @include media-breakpoint-up($md) {
      padding-top: 32px;
    }
  }
}
.questions-wrapper {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
.score-progress-card {
  @include media-breakpoint-up($sm) {
    max-width: 400px;
  }
}
.disclaimer-text {
  font-size: 14px;
  text-align: center;
  max-width: 650px;
}
</style>
