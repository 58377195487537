import { render, staticRenderFns } from "./DialogValidationCongratulations.vue?vue&type=template&id=64c13dc0&scoped=true"
import script from "./DialogValidationCongratulations.vue?vue&type=script&lang=js"
export * from "./DialogValidationCongratulations.vue?vue&type=script&lang=js"
import style0 from "./DialogValidationCongratulations.vue?vue&type=style&index=0&id=64c13dc0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c13dc0",
  null
  
)

export default component.exports